@font-face {
  font-family: "Futura";
  src: url("../fonts/Futura-Medium.eot");
  src: url("../fonts/Futura-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Futura-Medium.woff") format("woff"), url("../fonts/Futura-Medium.ttf") format("truetype"), url("../fonts/Futura-Medium.svg#Futura") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Bold.eot");
  src: url("../fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Bold.woff") format("woff"), url("../fonts/Montserrat-Bold.ttf") format("truetype"), url("../fonts/Montserrat-Bold.svg#Montserrat") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.eot");
  src: url("../fonts/Montserrat-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Regular.woff") format("woff"), url("../fonts/Montserrat-Regular.ttf") format("truetype"), url("../fonts/Montserrat-Regular.svg#Montserrat") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Light.eot");
  src: url("../fonts/Poppins-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Light.woff") format("woff"), url("../fonts/Poppins-Light.ttf") format("truetype"), url("../fonts/Poppins-Light.svg#Poppins") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-SemiBold.eot");
  src: url("../fonts/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-SemiBold.woff") format("woff"), url("../fonts/Poppins-SemiBold.ttf") format("truetype"), url("../fonts/Poppins-SemiBold.svg#Poppins") format("svg");
  font-weight: 600;
  font-style: normal;
}

:root {
  --color-white: #fff;
  --color-black: #181A1D;
  --color-subtitle: #4FB2F6;
  --color-title: #E5E5E5;
  --color-back: #181A1D;
  --color-back-main: #1D2831;
  --color-footer: #364149;
  --color-footer-border: #D1D1D2;
  --color-brands: #1A2025;
  --color-reading: #262938;
  --color-resources: #7A7A7A;
  --color-shadow: rgba(79, 178, 246, 0.5);
  --color-blog-social: rgba(29, 40, 49, 0.4);
  --gradient-circle: radial-gradient(36.25% 36.25% at 50% 50%, rgba(79, 178, 246, 0.3) 0%, rgba(24, 26, 29, 0.3) 100%);
  --Futura: 'Futura', sans-serif;
  --Montserrat: 'Montserrat', sans-serif;
  --Poppins: 'Poppins', sans-serif;
}

/* base styles*/
*, *:before,
*:after {
  box-sizing: border-box;
  outline: none;
}

*, body, html {
  -webkit-text-size-adjust: none;
  -webkit-appearance: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
}

html {
  font-size: 10px;
}

@media only screen and (min-width: 2560px) {
  html {
    font-size: 17.77px;
  }
}

@media only screen and (min-width: 1920px) {
  html {
    font-size: 13.3px;
  }
}

@media only screen and (max-width: 1680px) {
  html {
    font-size: 11.66px;
  }
}

@media only screen and (max-width: 1440px) {
  html {
    font-size: 10px;
  }
}

@media only screen and (max-width: 1366px) {
  html {
    font-size: 9.49px;
  }
}

@media only screen and (max-width: 1280px) {
  html {
    font-size: 8.88px;
  }
}

@media only screen and (max-width: 1024px) {
  html {
    font-size: 7.11px;
  }
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 5.33px;
  }
}

@media only screen and (max-width: 666px) {
  html {
    font-size: 10px;
  }
}
